import * as React from "react"

// styles
const pageStyles = {
  color: "#232129",
  padding: 96,
  fontFamily: "Roboto, -apple-system, sans-serif, serif",
}
const headingStyles = {
  marginTop: 0,
  marginBottom: 64,
  textAlign: 'center',
}

// markup
const IndexPage = () => {
  return (
    <main style={pageStyles}>
      <title>Start Page</title>
      <h1 style={headingStyles}>
        Something's coming...
      </h1>
    </main>
  )
}

export default IndexPage
